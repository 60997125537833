
import { Component, Vue } from 'vue-property-decorator'
import { DayStatisticalList } from '../../types/inspectionReport'
import { DicInfo, DicList } from '../../types/common'
import { dateBefore } from '../../utils/formatDate'

@Component
export default class DayStatistical extends Vue {
 private searchInfo: { dicCode: string; patrolStatus: string} = {
   dicCode: '', // 巡查类别
   patrolStatus: '' // 问题状态
 }

  private dateRange: Array<string> = dateBefore(6, true)
  private tableData: DayStatisticalList[] = []

  private loading = false
  private dicList: DicInfo[] = []
  private patrolStatus = ['未完成', '部分完成', '已完成']
  private page = 1
  private size = 10
  private total = 0

  created (): void {
    this.getCategories()
    this.getList()
  }

  // 巡查类别
  getCategories () {
    this.$axios.get<DicList>(this.$apis.common.selectDicByList, { dicType: 'patrolType' }).then(res => {
      this.dicList = res.patrolType || []
    })
  }

  // 获取列表数据
  getList (): void {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<DayStatisticalList>}>(this.$apis.inspectionReport.selectDaySumPage,
      {
        ...this.searchInfo,
        startDate: this.dateRange && this.dateRange[0] ? this.dateRange[0] : '',
        endDate: this.dateRange && this.dateRange[1] ? this.dateRange[1] : '',
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  statusFormat (value: string) {
    return this.patrolStatus[+value]
  }

  // 查看详情
  toDetail (row: DayStatisticalList) {
    this.$router.push({
      name: 'inspectionReportDayDetail',
      query: {
        patrolType: row.patrolType,
        patrolDate: row.patrolDate,
        patrolTypeName: row.patrolTypeName
      }
    })
  }

  onSearch (): void {
    this.page = 1
    this.getList()
  }
}
